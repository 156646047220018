<template lang="pug">
	div(v-if="$i18n.locale === 'de-DE'")
		frontpage-de
	//- Omdat er altijd een frontpage zou moeten zijn. Dan maar NL.
	div(v-else)
		frontpage-nl

</template>

<style lang="sass">
.frontpage
	@media #{map-get($display-breakpoints, 'xs-only')}
		margin-top: 150px

	.window.pull-up
		margin-top: 0 !important
	.breadcrumbs
		display: none

	.accommodation-finder
		.v-input
			font-size: 13px
		.accommodation-finder__intro
			font-size: 14px
			line-height: 22px

	.cta-video
		position: absolute
		top: 0
		left: 0
		right: 0
		padding: 0 0.8rem
		color: #fff
		background: rgba(22, 25, 39, 0.5)
		z-index: 1

.header-link__fill
	height: 100%
	align-items: flex-end
</style>

<script>
import FrontpageNl from '@/components/frontpage/nl.vue';
import FrontpageDe from '@/components/frontpage/de.vue';

export default {
	name: 'Frontpage',
	components: {
		FrontpageNl,
		FrontpageDe
	},
	asyncData({ app }) {
		app.ads({
			frontpage: { visible: true, isHouseAd: true },
			accofrontpage: { visible: true, type: 'content' },
			rotw: { visible: true, type: 'content' }
		});

		if (app.i18n.locale === 'de-DE') {
			app.meta({
				title: 'Dein Skiurlaub beginnt auf Skiportal.de',
				description:
					'Unabhängige Information über Skiurlaub, Skigebiete und Skiorte. Die ideale Vorbereitung auf deinen Skiurlaub beginnt hier.',
				hasFollow: true,
				hasIndex: true
			});
		} else if (app.i18n.locale === 'nl-NL') {
			app.meta({
				title: 'Wintersport begint op wintersport.nl',
				description:
					'Onafhankelijke informatie over wintersport, skigebieden en bestemmingen. De beste voorbereiding op een wintersportvakantie start hier.',
				hasFollow: true,
				hasIndex: true
			});
		} else if (app.i18n.locale === 'nl-BE') {
			app.meta({
				title: 'Wintersport begint op wintersport.be',
				description:
					'Onafhankelijke informatie over wintersport, skigebieden en bestemmingen. De beste voorbereiding op een wintersportvakantie start hier.',
				hasFollow: true,
				hasIndex: true
			});
		}
		app.breadcrumbs({}); // breadcrumbs are hidden anyway
	},
	head() {
		return {
			...this.$structuredData()
		};
	}
};
</script>
