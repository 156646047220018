<template lang="pug">
	content-page(v2).frontpage
		div(slot="heading")
			v-row(no-gutters)
				v-col(cols="12" sm="4" v-for="{id, title, item, path, type} in mappedFrontpageItems.slice(0,3)" :key="id")
					v-img.pa-6.pa-sm-3.pa-md-6(:aspect-ratio="16/9" :src="`${item.photo}?width=800`" :srcset="`${item.photo}?width=400 420w, ${item.photo}?width=640 640w, ${item.photo}?width=800 800w `" sizes="(max-width: 1260px) 420px, (max-width: 1920px) 640px, 800px"  :alt="title" gradient="180deg,transparent 0,#212121" )
						.d-flex.flex-column-reverse(style="height:100%;min-height:164px")
							template(v-if="type === 'custom'")
								a.header-link__fill.d-flex.grey--text(:href="item.url" target="_blank")
									.text-h6.text-sm-body-1.text-md-h5.text-lg-h4.font-weight-bold.white--text {{title}}
							template(v-else)
								v-row.white--text.shrink(no-gutters)
									v-col.mr-1.shrink.text-no-wrap
										nuxt-link(v-if="item.author && item.category !== 'advertorial'" :to="localePath({name:'profile-slug', params: {slug: item.author.slug}})" :title="item.author.username") {{item.author.username}}
										v-chip.px-1.mr-2.flex-grow-0.flex-shrink-0(label x-small color="grey lighten-2" v-if="item.category == 'advertorial'") gesponsord
									v-col.mr-1.shrink.text-no-wrap(v-if="item.displayDate") {{$t('weblog-item:published.at.{date}', {date: $dayjs(item.displayDate).format($vuetify.breakpoint.mdAndDown ? 'DD-MM-YYYY' : 'DD MMMM YYYY')})}}
									v-col.mr-1.shrink.text-no-wrap(v-if="item.replyCount")
										optional-nuxt-link.grey--text(:to="localePath({...path, hash: '#reacties'})") {{$tc('page.weblog.message:reply.{count}', item.replyCount, {count: item.replyCount})}}
								optional-nuxt-link.header-link__fill.d-flex.grey--text(:to="localePath({...path})")
									.text-h6.text-sm-body-1.text-md-h5.text-lg-h4.font-weight-bold.white--text {{title}}
									v-chip.px-1.flex-grow-0.flex-shrink-0.ml-3.mb-2(label x-small color="grey lighten-2" v-if="type === 'academylesson'") academy
		//- div(slot="heading")
		//- 	nuxt-link(style="background-color: #E9EFF7; display:block" :to="localePath({name: 'weblog-slug', params: { 0: '29/03/2021/bibian-mentel-overleden'}})")
		//- 		v-img(:src="require('~/assets/images/bibian.jpg')" max-height="380" max-width="1920" style="margin: 0 auto;" :gradient="$vuetify.breakpoint.xl ? 'to right, rgba(233, 239, 247,1) 0%,rgba(233, 239, 247,0) 5%,rgba(233, 239, 247,0) 95%,rgba(233, 239, 247,1) 100%' : ''")
		//- 			.window.accent--text.flex-column.d-flex.justify-center(style="height:100%; position:relative")
		//- 				.text-h6.text-sm-h4.text-md-h2.text-lg-h1.font-weight-bold.px-6 Bibian Mentel-Spee
		//- 				.text-sm-h5.text-md-h3.text-lg-h2.px-6 1972 - 2021
		//- 	.window.mt-md-n12(style="position:relative")
		//- 		a.caption.accent--text.px-6(href="https://mentelityfoundation.org" target="_blank") Foto: Mentelity Foundation
		div(slot="nav")
			navigation-default
		.flow-left
			portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="2")
				.mb-6
					ad(location="accofrontpage")

			v-data-iterator.mb-6(:items="mappedFrontpageItems.slice(3)" hide-default-footer)
				template(v-slot:item="{ item }")
					template(v-if="item.type === 'custom' || item.type === 'contentpage'")
						v-row
							v-col.shrink(cols="12" md="auto")
								a(:href="item.url" target="_blank")
									v-img(:aspect-ratio="16/9" :width="$vuetify.breakpoint.smAndUp ? 180: 'auto'" min-width="180" :src="`${item.photo}`" :alt="item.title")
							v-col.grow
								a.d-block.font-weight-bold.subtitle-1(:href="item.url" target="_blank") {{item.title}}
								p.text--primary {{item.content}}

					weblog-item(v-else :weblog="{...item.item, title: item.title}" :override-path="item.path")
			.my-6.text-center
				v-btn(outlined color="primary" :to="localePath({name:'weblog'})") meer weblogs

			// portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="2")
			// 	forecast-sidebar.mb-6

			.mb-6(v-if="forecastBlog")
				h2
					nuxt-link(:to="localePath({name: 'weblog-slug', params: { 0: forecastBlog.slug}})" :title="forecastBlog.title") {{forecastBlog.title}}
				.subtitle-1.grey--text.mb-1 {{$t('page.weather:weatherreport.{date}.title', {date: $dayjs(forecastBlog.displayDate).format('dddd D MMMM')})}}
				nuxt-link(:to="localePath({name: 'weblog-slug', params: { 0: forecastBlog.slug}})" :title="forecastBlog.title")
					v-img(:src="`${forecastBlog.photo}?width=1024`" :aspect-ratio="16/9")
				v-row.mt-1
					v-col.grow {{forecastBlog.content.introduction}}
						|
						|
						nuxt-link.text-no-wrap(:to="localePath({name: 'weblog-slug', params: { 0: forecastBlog.slug}})" :title="forecastBlog.title") » lees meer
					v-col.shrink.hidden-sm-and-down
						img(:alt="$t('page.weather:roel.title')" width="128px" src="https://static.wintersport.nl/images/roel_trans.png" style="float: right;" loading="lazy")

			portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="3")
				v-card.pa-2.mb-6.accommodation-finder(outlined)
					v-card-text
						.h3.black--text Vind de beste wintersport accommodatie
						.accommodation-finder__intro Elk uur controleren wij prijzen van ruim
							strong  58.762
							|  appartementen, hotels &amp; chalets bij ruim 12 reisorganisaties
					v-list.pt-0(nav dense style="background-color:transparent")
						.h4.mb-2.ml-2.font-weight-bold.black--text {{$t('accommodations-filters.heading.destination')}}
						v-autocomplete.mx-2.mb-4(
							outlined
							solo
							flat
							dense
							hide-details
							clearable
							append-icon=""
							return-object
							:placeholder="$t('accommodations-filters.destination.placeholder')"
							item-text="name"
							item-value="id"
							v-model="selection"
							:items="results"
							:search-input.sync="search")
							template(#item="data")
								v-list-item-avatar(v-if="data.item.countryCode" size="20" tile)
									img.flag.ma-0(:src="`https://static.wintersport.nl/images/flags/svg/${data.item.countryCode}.svg`")
								v-list-item-content {{data.item.name}}
								v-list-item-avatar(size="20")
									ws-icon(small) {{icons[data.item.__typename]}}
						.h4.mb-2.ml-2.font-weight-bold.black--text {{$t('accommodations-filters.heading.departure')}}
						minimum-filter.mx-1(@update="searchAccommodations")
							template(#action="{update}")
								v-btn.gradient.mt-4.mb-1(depressed @click="update") {{$t('components.accommodations.minimum-filter.home.search-button')}}

			portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="4")
				div
					h2 Laatste wintersportnieuws
					weblog-list-item(v-for="newsItem in news.weblogs" :key="newsItem.id" :weblog="newsItem")
					.my-6.text-center
						v-btn(outlined color="primary" :to="localePath({name:'news'})") meer nieuws

			portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="6")
				div
					h2 Wintersport materiaal
					p Ben je op zoek naar het nieuwste of beste materiaal voor je wintersport? We hebben voor jou de ideale
						|
						|
						nuxt-link(:to="localePath({name:'gear'})") productvergelijker
						|
						| zodat jij gemakkelijk tot de beste keuze komt!

			portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="7")
				div
					h3 Materiaal blogs
					weblog-list-item(v-for="weblog in gear.weblogs" :key="weblog.id" :weblog="weblog" hide-date)
					.my-6.text-center
						v-btn(outlined color="primary" :to="localePath({name:'gear'})") meer over materiaal

			client-only
				.mb-6(v-if="frontpageVideo")
					h2 {{frontpageVideo.title}}
					div(style="position:relative")
						.cta-video {{frontpageVideo.subtitle}}
						vue-plyr.mb-3(v-if="frontpageVideo.video.videoSource === 'youtube' || frontpageVideo.video.videoSource === 'vimeo'" ref='plyr' :options='{"controls":["play", "progress", "volume", "play-large", "airplay", "fullscreen", "pip", "mute"], "disableContextMenu": false}' :key="frontpageVideo.video.videoId")
							div(:data-plyr-provider="frontpageVideo.video.videoSource" :data-plyr-embed-id='frontpageVideo.video.videoId')
						video-hls(v-else-if="frontpageVideo.video.videoSource === 'video'" :videoUrl="frontpageVideo.video.videoId" theme="nskiv" controls="")
					.text-center
						v-btn(outlined color="primary" :to="localePath({name:'videos'})") meer videos


			portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="8")
				ad(location="frontpage")

			portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="9")
				div
					h2 Gratis online Wintersport Academy
					v-img.my-3(src="https://static.wintersport.nl/images/academy/ws-academy.jpg" :aspect-ratio="21/9")
					p Met de juiste kennis de bergen in maakt jouw vakantie een stuk prettiger dan je misschien denkt! Check de gratis online academy en spijker je kennis bij.
					.my-6.text-center
						v-btn(outlined color="primary" :to="localePath({name:'academy'})") wintersport academy

			h2 Nog meer wintersport!
			p We hebben genoeg informatie om jouw wintersport vakantie perfect te maken, maar wellicht kun je het niet allemaal vinden. Hieronder wat handige links en tips!

			h3 Vind je ideale vakantie en accommodatie
			v-row.mt-n3.mb-6
				read-more-paragraph(:to="localePath({name:'areas'})" title="Skigebieden overzicht") Informatie over 409 skigebieden in Oostenrijk, Frankrijk, Zwitserland, Italië en daarbuiten!
				read-more-paragraph(:to="localePath({name:'accommodations'})" title="Wintersport accommodatie") Elk uur vergelijken we ruim 58.762 accommodaties van 12 reisaanbieders.

			h3 Bereid je voor op je wintersport vakantie
			v-row.mt-n3.mb-6
				read-more-paragraph(:to="localePath({name:'weather'})" title="Wintersport weerbericht") Weerman Roel en ons eigen gemaakte weermodel zorgen ervoor dat jij precies weet wat het weer voor jouw bestemming gaat zijn.
				read-more-paragraph(:to="localePath({name:'academy'})" title="Wintersport Academy") In deze online lessen krijg je niet alleen de beste techniek tips, maar ook hoe je het meest uit je wintersport dag haalt én fit de piste op gaat!
				read-more-paragraph(:to="localePath({name:'localdestinations'})" title="Winterport in eigen land") Meer genieten van je wintersport vakantie start met voorbereiding in eigen land! Wist je dat we meer dan 91 locaties hebben in Nederland en België waar jij kan wintersporten?
				read-more-paragraph(:to="localePath({name:'verzekering-reisverzekering'})" title="Reisverzekering" v-if="$i18n.locale === 'nl-NL'") Zonder zorgen op pad tijdens je wintersport vakantie? Wij hebben de beste reisverzekering voor de wintersporter!
				read-more-paragraph(:to="localePath({name:'gear'})" title="Materiaal") Op zoek naar de ski die bij jou past? Of misschien een helm, goggle? We testen het hele seizoen door het laatste materiaal voor jou!

			h3 Omdat je nooit genoeg kan lezen
			v-row.mt-n3.mb-6
				read-more-paragraph(:to="localePath({name:'forum'})" title="Forum community") Buiten alles wat wij als wintersport.nl schrijven en publiceren hebben we een veel te danken aan jou! De bezoeker van de site. Praat mee met andere bezoekers en deel je ervaringen en kennis!


			portal(to="sidebar" :order="0")
				//- content-banner(v-if="regionOfTheWeek" v-bind="regionOfTheWeek")
				ad(location="rotw")
			portal(to="sidebar" :order="5")
				offers.mb-6



</template>

<script>
import gql from 'graphql-tag';
import ContentBanner from '@dengl/common/components/banners/content.vue';
import AccommodationBanner from '@dengl/common/components/banners/accommodation.vue';
import NavigationDefault from '@/components/navigation-default.vue';
import InsuranceAd from '@/components/insurance-ad';
import WeblogItem from '@/components/weblog-item';
import MinimumFilter from '@/components/accommodations/minimum-filter.vue';
import ForecastSidebar from '@/components/weather/forecast-sidebar.vue';
const { encodeFilters } = require('@dengl/common/utils/filterstrings');

const ReadMoreParagraph = {
	functional: true,
	render(createElement, context) {
		return createElement('v-col', { props: { cols: 12, lg: 6 } }, [
			createElement(
				'nuxt-link',
				{
					class: 'font-weight-bold',
					props: { to: context.props.to }
				},
				[context.props.title]
			),
			createElement(
				'p',
				{
					class: 'mb-0'
				},
				[
					context.children,
					createElement(
						'nuxt-link',
						{
							props: { to: context.props.to },
							class: 'text-no-wrap'
						},
						[' » lees meer']
					)
				]
			)
		]);
	}
};

const WeblogListItem = {
	props: {
		weblog: {
			type: Object,
			required: true
		},
		hideDate: {
			type: Boolean,
			default: false
		}
	},
	render(createElement, _context) {
		let col = null;
		if (this.hideDate) {
			const icon = createElement('ws-icon', { props: { small: true } }, [
				'angle-right'
			]);
			col = createElement('v-col', { class: 'shrink text-no-wrap' }, [icon]);
		} else {
			const pubDate = this.$dayjs(this.weblog.displayDate);
			const formattedDate = this.$dayjs().isSame(pubDate, 'day')
				? pubDate.format('HH:mm')
				: pubDate.format('DD-MM');
			col = createElement(
				'v-col',
				{ class: 'shrink grey--text text-no-wrap mr-2' },
				[formattedDate]
			);
		}

		return createElement('v-row', { props: { noGutters: true } }, [
			col,
			createElement('v-col', { class: 'grow' }, [
				createElement(
					'nuxt-link',
					{
						props: {
							to: this.localePath({
								name:
									this.weblog.category === 'news' ? 'news-slug' : 'weblog-slug',
								params: { 0: this.weblog.slug }
							})
						}
					},
					[this.weblog.title]
				)
			])
		]);
	}
};

const frontpageQuery = gql`
	query frontpage {
		frontpageItems {
			id
			title
			type
			image
			content
			url
			item {
				... on Weblog {
					...WeblogItem
				}
				... on Contentpage {
					id
					path
					title: name
					content {
						introduction(length: 120)
					}
					meta {
						image
					}
				}
				... on Topic {
					id
					title
					slug
					meta {
						image
					}
					displayDate: created_at
					replyCount
					author: profile {
						id
						slug
						username
					}
					content {
						introduction(length: 120)
					}
				}
				... on AcademyLesson {
					id
					title: name
					slug
					meta {
						image
					}
					content {
						introduction(length: 120)
					}
					chapter {
						id
						level {
							id
							slug
						}
					}
				}
			}
		}
		frontpageVideo {
			id
			title
			subtitle
			video {
				videoId
				videoSource
			}
		}
		weblogsByCategory(limit: 1, category: "weather") {
			weblogs {
				id
				title
				slug
				content {
					introduction(length: 240)
				}
				displayDate
				url
				photo
				replyCount
				category
				# author {
				# 	id
				# 	username
				# 	signature
				# 	teamDescription
				# 	role
				# }
				author: authorProfile {
					id
					username
					signature
					teamDescription
					# role
				}
			}
		}
		nskiv: weblogsByCategory(limit: 4, category: "nskiv") {
			weblogs {
				id
				slug
				title
				displayDate
				category
			}
		}
		gear: weblogsByCategory(limit: 4, category: "gear") {
			weblogs {
				id
				slug
				title
				displayDate
				category
			}
		}
		news: weblogsByCategory(limit: 8, category: "news") {
			weblogs {
				id
				slug
				title
				displayDate
				category
			}
		}
	}
	${WeblogItem.fragment}
`;

export default {
	name: 'FrontpageNL',
	components: {
		NavigationDefault,
		InsuranceAd,
		WeblogItem,
		MinimumFilter,
		WeblogListItem,
		ContentBanner,
		ReadMoreParagraph,
		ForecastSidebar,
		AccommodationBanner
	},
	data() {
		return {
			frontpageItems: [],
			frontpageVideo: null,
			weblogsByCategory: {
				weblogs: []
			},
			nskiv: {
				weblogs: []
			},
			gear: {
				weblogs: []
			},
			news: {
				weblogs: []
			},
			// regionOfTheWeek: null,
			isSearching: false,
			search: '',
			results: [],
			selection: {},
			icons: {
				Area: 'ski',
				Resort: 'village',
				Cluster: 'ski',
				Organisation: 'organisation',
				Skihall: 'skihall',
				Revolvingslope: 'revolvingslope',
				Dryslope: 'dryslope',
				Carpetslope: 'carpetslope'
			}
		};
	},
	fetch() {
		return this.$graphClient
			.query({
				query: frontpageQuery,
				errorPolicy: 'all'
			})
			.then(({ data, errors }) => {
				if (errors) {
					throw errors[0];
				}

				// if (data.regionOfTheWeek) {
				// 	data.regionOfTheWeek.subtitle = data.regionOfTheWeek.title;
				// 	data.regionOfTheWeek.title = 'Regio van de week';
				// 	data.regionOfTheWeek.url = `/redir?u=${encodeURIComponent(
				// 		data.regionOfTheWeek.url
				// 	)}&p=${
				// 		data.regionOfTheWeek.partner
				// 			? data.regionOfTheWeek.partner.slug
				// 			: 'null'
				// 	}&i=${data.regionOfTheWeek.id}&t=rotw&h=${this.$redirHash(
				// 		data.regionOfTheWeek.url
				// 	)}`;
				// }

				this.frontpageItems = data.frontpageItems;
				this.frontpageVideo = data.frontpageVideo;
				this.weblogsByCategory = data.weblogsByCategory;
				this.nskiv = data.nskiv;
				this.gear = data.gear;
				this.news = data.news;
				// this.regionOfTheWeek = data.regionOfTheWeek;
			})
			.catch(error => {
				return this.httpError(500, null, 'frontpage-01', error);
			});
	},
	computed: {
		forecastBlog() {
			return this.weblogsByCategory.weblogs[0];
		},
		mappedFrontpageItems() {
			return this.frontpageItems.map(item => {
				const itemCopy = this.$lodash.cloneDeep(item);

				if (itemCopy.type === 'custom') {
					itemCopy.item = itemCopy;
					if (itemCopy.image) itemCopy.item.photo = itemCopy.image;
				}

				itemCopy.item.title = itemCopy.title;

				if (itemCopy.type === 'topic') {
					itemCopy.item.photo = itemCopy.item.meta.image;
					itemCopy.path = {
						name: 'forum-topic',
						params: { slug: itemCopy.item.slug }
					};
				}
				if (itemCopy.type === 'weblog') {
					itemCopy.path = {
						name:
							itemCopy.item.category === 'news' ? 'news-slug' : 'weblog-slug',
						params: { 0: itemCopy.item.slug }
					};
				}
				if (itemCopy.type === 'contentpage') {
					itemCopy.item.type = itemCopy.type;
					itemCopy.item.photo = itemCopy.item.meta.image;
					itemCopy.photo =
						itemCopy.item.meta.image ||
						'https://static.wintersport.nl/images/ogimages/ws-wintersporters.jpg';
					itemCopy.content = itemCopy.item.content.introduction || '';
					itemCopy.path = {
						name: 'contentpage',
						params: { 0: itemCopy.item.path.slice(1) }
					};
					itemCopy.item.url = this.localePath({
						name: 'contentpage',
						params: { 0: itemCopy.item.path.slice(1) }
					});
				}
				if (itemCopy.type === 'academylesson') {
					itemCopy.item.photo = itemCopy.item.meta.image;
					itemCopy.path = {
						name: 'academy-level-lesson',
						params: {
							level: itemCopy.item.chapter.level.slug,
							lesson: itemCopy.item.slug
						}
					};
				}

				itemCopy.item.photo =
					itemCopy.item.photo ||
					'https://static.wintersport.nl/images/ogimages/ws-wintersporters.jpg';

				return itemCopy;
			});
		}
	},
	watch: {
		search(val) {
			if (!val || val.length < 3) return;
			this.searchDestinations(val);
		},
		selection(val) {
			this.selectSearch(val);
		}
	},
	methods: {
		searchDestinations(val) {
			this.isSearching = true;

			const searchQuery = gql`
				query search($val: String!, $types: [String]!, $limit: Int!) {
					search(term: $val, types: $types, limit: $limit) {
						__typename
						... on Country {
							id
							name
							slug
							countryCode
						}
						... on Region {
							id
							name
							slug
							country {
								id
								countryCode
							}
						}
						... on Area {
							id
							name
							slug
							country {
								id
								countryCode
							}
						}
						... on Resort {
							id
							name
							slug
							country {
								id
								countryCode
							}
						}
					}
				}
			`;

			this.$graphClient
				.query({
					query: searchQuery,
					variables: {
						val,
						types: ['countries', 'regions', 'areas', 'resorts'],
						limit: 8
					}
				})
				.then(({ data }) => {
					this.results = data.search.map(r => {
						if (['Region', 'Area', 'Resort'].includes(r.__typename))
							return {
								...r,
								countryCode: r.country ? r.country.countryCode : null
							};

						return r;
					});
				})
				.catch(_err => {})
				.finally(() => (this.isSearching = false));
		},
		selectSearch(item) {
			if (!item) return false;
		},
		searchAccommodations(filters) {
			let path = 'accommodations-search';
			const params = {};
			switch (this.selection.__typename) {
				case 'Area':
					path = 'areas-area-accommodationtype';
					params.area = this.selection.slug;
					params.accommodationtype = this.$localeSlug(
						'accommodations',
						this.$i18n.locale
					);
					break;
				case 'Resort':
					path = 'resorts-resort-accommodationtype';
					params.resort = this.selection.slug;
					params.accommodationtype = this.$localeSlug(
						'accommodations',
						this.$i18n.locale
					);
					break;
				case 'Country':
					filters.countries = [this.selection.countryCode];
					break;
				case 'Region':
					filters.regions = [this.selection.slug];
					break;
				default:
					break;
			}

			this.$router.push(
				this.localePath({
					name: path,
					params,
					hash: `#${encodeFilters(filters, this.$i18n.locale)}`
				})
			);
		}
	}
};
</script>
