<template lang="pug">
div
	span(v-if="snowheight && $dayjs().isBefore(snowheight.seasonStartsAt)") {{$t('page.areas._area.snowheight:expected_opening_area_{date}', {date: $dayjs(snowheight.seasonStartsAt).format('D MMMM')})}}
	.caption.grey--text {{$t('page.areas._area.snowheight:label_last_update')}} {{$dayjs(snowheight.updatedAt).format('D MMMM YYYY')}}
	v-row
		v-col.mt-4.shrink(cols="12" lg="6")
			mountain-snowheight-svg(v-bind="snowheight")
		v-col.shrink(cols="12" sm="6" lg="3")
			v-list(two-line)
				v-list-item
					v-list-item-avatar(size="48")
						pie(:percentage="snowheight.elevatorsOpen / snowheight.elevatorsTotal * 100" :size="48")
					v-list-item-content
						v-list-item-title.subtitle-1 {{$t('page.areas._area.snowheight:skilifts_open')}}
						v-list-item-subtitle {{snowheight.elevatorsOpen}} van de {{snowheight.elevatorsTotal}}
				v-list-item
					v-list-item-avatar(size="48")
						pie(:percentage="snowheight.slopeLengthOpen / snowheight.slopeLength * 100" :size="48")
					v-list-item-content
						v-list-item-title.subtitle-1 {{$t('page.areas._area.snowheight:slopes_open')}}
						v-list-item-subtitle {{snowheight.slopeLengthOpen}} van {{snowheight.slopeLength}}km
		v-col(cols="12" sm="6" lg="3")
			v-sheet.mb-2.d-inline-block.px-3.label.text-center.font-weight-bold(v-if="snowheight.isResortOpen" dark color="success") {{$t('page.areas._area.snowheight:resort_open:yes')}}
			v-sheet.mb-2.d-inline-block.px-3.label.text-center.font-weight-bold(v-else dark color="error") {{$t('page.areas._area.snowheight:resort_open:no')}}

			stat-row(icon="snow") {{$t('page.areas._area.snowheight:label_snow_quality')}} {{$t(`page.areas._area.snowheight:snowtype.${snowheight.slopeStatusSlug}`)}}
			stat-row(icon="clock-o")
				| {{$t('page.areas._area.snowheight:label_last_snow')}}
				.no-wrap(v-if="snowheight.lastSnowAt") {{$dayjs(snowheight.lastSnowAt).from($dayjs())}}
				.no-wrap(v-else) {{$t('page.areas._area.snowheight:value_last_snow_unknown')}}
			stat-row(v-if="snowheight.isDownToValleyOpen" icon="check" icon-color="success") {{$t('page.areas._area.snowheight:label_down_to_valley_open')}}
			stat-row(v-else icon="cross" icon-color="error") {{$t('page.areas._area.snowheight:label_down_to_valley_closed')}}
			stat-row(v-if="snowheight.hasRodel && snowheight.rodelLengthOpen > 0 || snowheight.rodelLengthOpen > 0" icon="check" icon-color="success") {{$t('page.areas._area.snowheight:label_rodel_open')}}
			stat-row(v-else icon="cross" icon-color="error") {{$t('page.areas._area.snowheight:label_rodel_closed')}}
</template>

<script>
import gql from 'graphql-tag';
import MountainSnowheightSvg from './mountain-snowheight-svg.vue';
import Pie from '@/components/display/pie.vue';

const StatRow = {
	functional: true,
	render(createElement, context) {
		return createElement('v-row', { props: { dense: true } }, [
			createElement('v-col', { class: 'shrink' }, [
				createElement(
					'ws-icon',
					{ props: { size: '20', color: context.props.iconColor || null } },
					[context.props.icon]
				)
			]),
			createElement('v-col', {}, context.children)
		]);
	}
};

export default {
	components: { Pie, MountainSnowheightSvg, StatRow },
	props: {
		snowheight: {
			type: Object,
			required: true
		}
	},
	data() {
		return {};
	},
	computed: {
		upToDate() {
			return this.$dayjs().diff(this.snowheight.updatedAt, 'day') <= 7;
		}
	},
	fragments: gql`
		fragment SnowheightStatCardData on Snowheight {
			updatedAt
			isResortOpen
			location
			snowMountain
			correctionMountain
			snowValley
			correctionValley
			elevationMountain: areaHeightMax
			elevationValley: areaHeightMin
			elevatorsTotal
			elevatorsOpen
			slopeLength
			slopeLengthOpen
			slopeStatus
			slopeStatusSlug
			lastSnowAt
			seasonStartsAt
			seasonEndsAt
			isDownToValleyOpen
			hasRodel
			rodelLengthOpen
			snowLast24
		}
	`
};
</script>
