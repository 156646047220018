<template lang="pug">
div
	span
		nuxt-link.h2(:to="destinationLink('forecast')") {{$t('forecast.sidebar.label.forecast_{destination}', {destination: forecastDestination.name})}}
		login-action(@on-click="editUserDestination()")
			template(#activator="{ on }")
				a.ml-1(v-on="on") {{$t('forecast.sidebar.label.change-destination')}}

	validation-observer(v-slot="{ handleSubmit, invalid, dirty}")
		ws-dialog(v-model="destinationDialog" :dirty="dirty" :title="$t('forecast.sidebar:edit.destination.dialog.heading')")
			form(@submit.prevent="handleSubmit(changeDestination)")
				v-card-text
					.h3.mb-4 {{$t('forecast.sidebar.new.destination.dialog.title')}}
					validation-provider(v-slot="{errors, touched}" rules="required" :name="$t('forecast.sidebar:edit.destination.dialog.destination.input.error')" slim)
						destination-selector.mb-3(outlined :label="$t('forecast.sidebar:edit.destination.dialog.destination.input')" v-model="newDestination" hide-details="auto" :error-messages="touched ? errors : []")
					datepicker(outlined :label="`${$t('forecast.sidebar:edit.destination.dialog.destination.date')}`" v-model="newDestinationDate" hide-details="auto")
					div(v-if="futureUserDestinations.length")
						.h3.mt-6.mb-4 {{$t('forecast.sidebar:edit.destination.dialog.suggestions')}}
						v-list(style="overflow-y:scroll" max-height="400" subheader)
							template(v-for="{userDestination, arrival_date} in futureUserDestinations")
								v-hover(v-slot:default="{ hover }" )
									v-list-item.text-no-wrap(dense)
										v-list-item-content
											v-list-item-title
												a.font-weight-bold(@click="setUserDestination(userDestination, arrival_date)") {{userDestination.name}}
										v-list-item-action(v-if="arrival_date")
											v-list-item-action-text(v-text="$dayjs(arrival_date).format('D MMMM YYYY')")

								v-divider
				v-card-actions
					v-spacer
					v-btn(outlined color="primary" @click="destinationDialog = false;") {{$t('forecast.sidebar:edit.destination.dialog.cancel')}}
					v-btn(color="primary" depressed type="submit" :disabled="invalid") {{$t('forecast.sidebar:edit.destination.dialog.submit')}}

	template(v-if="area.snowheights && area.snowheights.nodes.length")
		.caption.mt-3
			span.success--text(v-if="area.snowheights && area.snowheights.nodes[0].isResortOpen") ●
			span.error--text(v-else) ●
			|
			|
			span(v-if="area.snowheights && area.snowheights.nodes[0].isResortOpen")
				strong {{$t('forecast.sidebar.label.open')}}
				|  ·
				|  {{$t('forecast.sidebar.label.pistes')}}
				strong.success--text  {{area.snowheights.nodes[0].slopeLengthOpen}}/{{area.snowheights.nodes[0].slopeLength}}
				| km ·
			strong(v-else) {{$t('forecast.sidebar.label.closed')}}
			|  ·
			span  {{$t('forecast.sidebar.label.snowheight-valley')}}
			strong.accent--text(v-if="area.snowheights")  {{area.snowheights.nodes[0].snowValley}}cm
			|  ·
			span  {{$t('forecast.sidebar.label.snowheight-mountain')}}
			strong.mr-1.accent--text(v-if="area.snowheights")  {{area.snowheights.nodes[0].snowMountain}}cm
			template(v-if="$store.state.user && daysUntilLeaving !== null")
				span(v-html="$tc('forecast.sidebar.label.{count}_days', daysUntilLeaving)")
				| ·
			nuxt-link(v-if="forecastDestination.stats && forecastDestination.stats.lessonCount" :to="destinationLink('lesson')")  {{$t('forecast.sidebar.label.skiles')}}
	div.my-6(v-else)
		small {{$t('forecast.sidebar:no.snow')}}

	table.forecast-table.mb-6.mt-3(v-if="area.forecasts && area.forecasts.length")
		tbody
			tr(v-for="forecast, index in area.forecasts" :key="forecast.realdate" height="40")
				td.text-capitalize.tablecell--narrow {{$dayjs(forecast.realdate).format('dd')}}
				td.tablecell--medium
					img(:src="`https://static.wintersport.nl/images/weather/${forecast.icon}.svg`" width="25px")
				td(style="word-spacing: 4px;")
					span {{$t('forecast.sidebar.tablecell.mountain')}}
					|
					|
					display-temp(tag="span" showBold) {{forecast.temperature}}
				td(style="word-spacing: 4px;" v-if="activeresort")
					span {{$t('forecast.sidebar.tablecell.valley')}}
					|
					|
					display-temp(tag="span" showBold) {{activeresort.forecasts[index].temperature}}
				td.tablecell--narrow.text-right
					v-chip.px-1.accent--text(color="#4AA3DF26" label v-if="forecast.snow > 0") {{forecast.snow}}cm
					small.px-1.my-1.grey--text(v-else) -
	div.my-6(v-else)
		small {{$t('forecast.sidebar:no.forecast')}}
	.text-center
		v-btn(:to="destinationLink('forecast')" outlined depressed color="primary") {{$t('forecast.sidebar.btn.show.full.forecast')}}

</template>

<style lang="sass">
.forecast-table
	width: 100%
	border-collapse: collapse
	tr
		border-top: 1px solid var(--v-border-base)
		border-bottom: 1px solid var(--v-border-base)
		td
			width: 27%
			img
				vertical-align: middle
			&.tablecell--narrow
				width: 12%
			&.tablecell--medium
				width: 22%
</style>

<script>
import gql from 'graphql-tag';
import Datepicker from '@/components/common/datepicker';
import DestinationSelector from '@/components/common/destination-selector';
import DisplayTemp from '@/components/display/temperature.vue';
import DisplayWind from '@/components/display/wind.vue';
import SnowheightsStatsCard from '@/components/snowheights/stats-card.vue';

const query = gql`
	query getAreaForecast($id: ID!, $forecastId: UUID!) {
		forecast(id: $forecastId) {
			forecast3 {
				hour
				zeroline
			}
			forecast6 {
				hour
				realdate
				rain
				snow
				temperature
				windDirection
				windSpeed
				icon
			}
			forecast24 {
				hour
				realdate
				rain
				snow
				temperature
				icon
				windDirection
				windSpeed
				sunScore
			}
		}
		area(id: $id) {
			id
			name
			slug
			measurepoints {
				id
				forecastRegion
			}
			snowheights {
				nodes {
					...SnowheightStatCardData
				}
			}
			resorts {
				nodes {
					id
				}
			}
		}
		user: me {
			id
			destinations {
				id
				userDestination: destination {
					id
					name
					slug
					__typename
				}
				arrival_date
			}
			forecastDestination {
				destination {
					... on Area {
						id
						name
						slug
						__typename
						stats {
							lessonCount
						}
						measurepoints {
							id
							forecastRegion
						}
						snowheights {
							nodes {
								...SnowheightStatCardData
							}
						}
						resorts {
							nodes {
								id
							}
						}
					}
					... on Resort {
						id
						name
						slug
						__typename
						stats {
							lessonCount
						}
						measurepoints {
							id
							forecastRegion
						}
						area {
							id
							name
							slug
							__typename
							snowheights {
								nodes {
									...SnowheightStatCardData
								}
							}
							resorts {
								nodes {
									id
								}
							}
						}
					}
				}
				date
			}
		}
	}
	${SnowheightsStatsCard.fragments}
`;

export default {
	components: {
		DisplayTemp,
		DisplayWind,
		DestinationSelector,
		Datepicker
	},
	data() {
		return {
			area: {},
			user: {},
			activeResortIndex: 0,
			destinationDialog: false,
			activeDestination: {},
			defaultDestination: {
				destination: {
					id: 'ed5a3dfc-017c-5ae6-9239-484701082866',
					__typename: 'Area',
					name: 'Stubaier Gletscher',
					slug: 'stubaiergletscher',
					measurepoints: [{ id: 'ed5a3dfc-017c-5ae6-9239-484701082866' }]
				},
				arrival_date: null
			},
			newDestination: null,
			newDestinationDate: null
		};
	},
	fetch() {
		this.fetchForecast();
	},
	computed: {
		activeresort() {
			return this.area.resorts
				? this.area.resorts.nodes[this.activeResortIndex]
				: null;
		},
		forecastArea() {
			const destination =
				this.user && this.user.forecastDestination
					? this.user.forecastDestination.destination
					: this.defaultDestination.destination;
			return destination.__typename === 'Area' ? destination : destination.area;
		},
		forecastDestination() {
			return this.user && this.user.forecastDestination
				? this.user.forecastDestination.destination
				: this.defaultDestination.destination;
		},
		daysUntilLeaving() {
			if (!this.activeDestination.date) {
				return null;
			}

			return this.$dayjs(this.activeDestination.date).diff(
				this.$dayjs().format('YYYY-MM-DD'),
				'day'
			);
		},
		futureUserDestinations() {
			if (this.user && this.user.destinations) {
				return this.user.destinations.filter(destination => {
					if (destination.arrival_date) {
						return this.$dayjs().isBefore(
							this.$dayjs(destination.arrival_date)
						);
					}

					return false;
				});
			}

			return false;
		}
	},
	watch: {
		activeDestination: {
			handler(_value) {
				this.fetchForecast().then(() => true);
			},
			deep: true
		}
	},
	methods: {
		fetchForecast() {
			return this.$graphClient
				.query({
					query,
					variables: {
						id: this.forecastArea.id,
						forecastId: this.forecastArea.measurepoints[0].id
					}
				})
				.then(({ data }) => {
					this.area = data.area;
					this.user = data.user;

					// set user forecast-destination as area
					if (this.user && this.user.forecastDestination) {
						const destination = this.user.forecastDestination.destination;
						this.area =
							destination.__typename === 'Area'
								? destination
								: destination.area;
					}
				});
		},
		editUserDestination() {
			this.destinationDialog = true;
		},
		changeDestination() {
			if (!this.user.forecastDestination) this.user.forecastDestination = {};
			this.user.forecastDestination.destination = this.newDestination;
			this.user.forecastDestination.date = this.newDestinationDate;
			this.$graphClient
				.mutate({
					mutation: gql`
						mutation ($destination: UserForecastDestinationInput!) {
							saveUserForecast(destination: $destination) {
								destination {
									... on Area {
										id
									}
									... on Resort {
										id
									}
								}
							}
						}
					`,
					errorPolicy: 'all',
					variables: {
						destination: {
							id: this.user.forecastDestination.destination.id,
							type: this.user.forecastDestination.destination.__typename,
							date: this.user.forecastDestination.date
						}
					}
				})
				.then(({ _data, errors }) => {
					if (errors) {
						return this.$toast.error(
							this.$t('forecast.sidebar.error.while.saving')
						);
					}
					this.$toast.success(
						this.$t('forecast.sidebar.destination.change.success')
					);
				})
				.finally(() => this.fetchForecast());
			this.newDestination = null;
			this.newDestinationDate = null;
			this.destinationDialog = false;
		},
		setUserDestination(destination, date) {
			this.newDestination = {
				id: destination.id,
				name: destination.name,
				slug: destination.slug,
				__typename: destination.__typename
			};
			this.newDestinationDate = date;

			this.changeDestination();
		},
		destinationLink(path) {
			if (this.forecastDestination.__typename === 'Area') {
				return this.localePath({
					name: `areas-area-${path}`,
					params: { area: this.forecastDestination.slug }
				});
			} else if (this.forecastDestination.__typename === 'Resort') {
				return this.localePath({
					name: `resorts-resort-${path}`,
					params: { resort: this.forecastDestination.slug }
				});
			}
		}
	}
};
</script>
