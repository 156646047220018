<template lang="pug">
svg.pie(:width="size" :height="size")
  circle.pie-background(:cx="radius" :cy="radius" :r="radius")
  circle(:r="radius / 2" :cx="radius" :cy="radius" fill='transparent' stroke='#3796c9' :stroke-dasharray="dashArray" :transform="`rotate(-90) translate(-${size})`" :stroke-width="radius" )
</template>

<style lang="sass">
.pie .arc:nth-child(2) path
  fill: #3796c9

.pie .pie-background
  fill: #f2fbff !important
</style>


<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 25
    },
    size: {
      type: Number,
      default: 60
    }
  },
  computed: {
    radius: function() {
      return this.size / 2;
    },
    circumference: function() {
      return (Math.PI * this.size) / 2;
    },
    dashArray: function() {
      const segment = Math.round((this.percentage * this.circumference) / 100);
      const gap = this.circumference;
      return `${segment} ${gap}`;
      // return '75% 25%';
    },
    dashOffset: function() {
      return `-${Math.round((25 * this.circumference) / 100)}`;
      // return '-90';
      // return (-90 * this.circumference) / 100;
    }
  }
};
</script>
