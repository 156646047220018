<template lang="pug">
v-menu(v-model="showMenu" :close-on-content-click="false" max-width="290")
	template(v-slot:activator="{ on }")
		v-text-field(:value="formattedDate" readonly v-on="on" append-icon="event" v-bind="$attrs")
	v-date-picker(v-model="date" @change="showMenu = false" :locale="$i18n.locale")
</template>

<script>
export default {
	props: {
		value: { type: String, default: '' }
	},
	data() {
		return {
			showMenu: false,
			date: ''
		};
	},
	computed: {
		formattedDate() {
			return this.date && this.$dayjs(this.date).format('DD-MM-YYYY');
		}
	},
	watch: {
		date() {
			this.$emit('input', this.date);
		}
	},
	created() {
		this.date = this.value === '' ? this.$dayjs().format() : this.value;
	}
};
</script>
